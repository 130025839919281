<template>
  <div>
    <v-card tile>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="9">
            <span class="judul">My Info</span>
            <br />
            <br />
            <v-simple-table>
              <template v-slot:default>
                <tr>
                  <td class="text-left">
                    Name
                  </td>
                  <td class="text-left" colspan="2">
                    {{ user.fullname }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    NIK
                  </td>
                  <td class="text-left" colspan="2">
                    {{ user.karyawan.nik }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Phone
                  </td>
                  <td class="text-left" colspan="2">
                    {{ user.karyawan.phone }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Email
                  </td>
                  <td class="text-left" colspan="2">
                    {{ user.karyawan.email }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Address
                  </td>
                  <td class="text-left" colspan="2">
                    {{ user.karyawan.address }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Jobtitle
                  </td>
                  <td class="text-left" colspan="2">
                    {{ user.karyawan.jabatan.name }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Branch
                  </td>
                  <td class="text-left" colspan="2">
                    {{ user.branch_name }}
                  </td>
                </tr>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="3">
            <span class="judul">Setting</span>
            <br />
            <br />
            <v-btn depressed color="success" small @click="close()"
              >Change Branch</v-btn
            >
            <br />
            <br />
            <v-btn depressed color="red" small @click="changePasswordDialog()"
              >Change Password</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
      width="650"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          Change Branch
          <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-select
              :menu-props="{ contentClass: 'dd-list-branch' }"
              :items="branchs"
              label="Branch"
              item-text="name"
              item-value="id"
              v-model="branch"
            ></v-select>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" tile class="custom-modal-title" @click="close">
            No
          </v-btn>

          <v-btn color="success" tile @click="changeBranch">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_password"
      width="950"
      @click:outside="changePasswordDialog()"
      @keydown.esc="changePasswordDialog()"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          Change Password
          <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="password_old"
                    label="Old Password"
                    required
                    type="password"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    label="Password"
                    required
                    type="password"
                  ></v-text-field>
                  <v-text-field
                    v-model="password_confirmation"
                    :rules="[passwordConfirmRules(password)]"
                    label="Password Confirmation"
                    required
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="changePasswordDialog"
          >
            Cancel
          </v-btn>

          <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="changePassword()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="css" scoped>
tr td:nth-child(1) {
  font-weight: bold;
}
td {
  padding: 10px 20px 10px 20px;
}
.judul {
  font-weight: bold;
  color: black;
}

/* .table tr td, .table tr th {
      padding: 10px;
      text-align: left;
  }
    .table tr th {
        background-color:#f5f5f5;
        color: black;
    } */
</style>

<script>
// @ is an alias to /src\
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      branch: "",
      valid: true,
      lazy: false,
      loading: false,
      password: "",
      password_old: "",
      password_confirmation: "",
      passwordConfirmRules(password) {
        return v => v === password || "Must be same password";
      },
      dialog_password: false
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      branchs: "data/branchs",
      color: "layout/color"
    }),
    backgroundColor() {
      return `background-color:${this.color} !important;color:white`;
    }
  },
  components: {},
  methods: {
    ...mapActions({
      setData: "data/setData",
      setUser: "auth/setUser",
      setSnakbar: "snakbar/setSnakbar"
    }),
    close() {
      this.dialog = !this.dialog;
    },
    changePasswordDialog() {
      this.dialog_password = !this.dialog_password;
    },
    changePassword() {
      let data = new FormData();
      data.append("password", this.password);
      data.append("password_long", this.password_old);
      this.axios
        .post("/v1/auth/change-password/" + this.user.id, data)
        .then(async ress => {
          this.setSnakbar({
            status: true,
            pesan: "Success submit done",
            color_snakbar: "success"
          });
          this.changePasswordDialog();
        })
        .catch(err => {
          this.setSnakbar({
            status: true,
            pesan: err.response.data.message,
            color_snakbar: "red"
          });
        });
    },
    async getBranch() {
      await this.axios
        .get("/v1/branch/all?company_id=" + this.user.company_id, {
          headers: {
            Authorization: "Bearer " + this.user.token
          }
        })
        .then(async ress => {
          await this.setData({ data: ress.data.data, table_name: "branchs" });
        })
        .catch(err => console.log(err));
    },
    async changeBranch() {
      let data = new FormData();
      data.append("branch_id", this.branch);
      await this.axios
        .put("v1/karyawan/update/" + this.user.karyawan.id, data)
        .then(async ress => {
          console.log(ress);
          let branch = this.branchs.find(x => x.id === this.branch);
          console.log(branch);
          await this.setUser({
            ...this.user,
            branch_id: branch.id,
            branch_name: branch.name
          });
        })
        .catch(err => console.log(err.response));
      this.close();
    }
  },
  async created() {
    this.branch = this.user.branch_id;
    await this.getBranch();
  },
  async mounted() {}
};
</script>
